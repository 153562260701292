import { Component, EventEmitter, Inject, LOCALE_ID, OnInit, Output } from '@angular/core';
import {
  ErrorVm,
  OrganisationsService,
  OrganisationVm,
  ProjectsService,
  ProjectSummaryVm,
  SupplierInvitationCreateRequestVm,
  SupplierInvitationVm,
} from 'app/shared/api';
import { AuthRepository } from 'app/core/state/auth.repository';
import { TranslateService } from '@ngx-translate/core';
import { NotificationService } from 'app/core/services/notification.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DefaultNationality } from '../../models/invitation-default-nationality';
import { finalize } from 'rxjs';
import { BroadcastService } from 'app/core/services/broadcast.service';
import { EventsName } from 'app/core/services/broadcast-events-name.model';
import { LoaderService } from 'app/core/services/loader.service';
import { RightName } from 'app/core/rights/right-name.model';

@Component({
  selector: 'sywa-invite-organisation-form',
  templateUrl: './invite-organisation-form.component.html',
  styleUrls: ['./invite-organisation-form.component.scss'],
})
export class InviteOrganisationFormComponent implements OnInit {
  @Output() submitSucceeded = new EventEmitter<void>();
  @Output() canceled = new EventEmitter<void>();
  @Output() needToAddOrganisation = new EventEmitter<void>();

  currentUserOrganisation: OrganisationVm;
  listManagedProjects: ProjectSummaryVm[];
  invitation: SupplierInvitationCreateRequestVm;
  selectedProject: ProjectSummaryVm;
  selectedCountry: string = DefaultNationality;
  selectedOrganisation: OrganisationVm;

  isSubmitting: boolean;

  supplierTypes = Object.values(SupplierInvitationCreateRequestVm.SupplierTypeEnum);
  RightName = RightName;

  constructor(
    @Inject(LOCALE_ID) public locale: string,
    private authRepository: AuthRepository,
    private organisationsService: OrganisationsService,
    private projectsService: ProjectsService,
    private translateService: TranslateService,
    private notificationService: NotificationService,
    private broadcastService: BroadcastService,
    private loaderService: LoaderService
  ) {}

  ngOnInit(): void {
    this.currentUserOrganisation = this.authRepository.getCurrentUserOrganisationSnapshot();

    this.organisationsService
      .getProjectsForInviteSupplier(this.currentUserOrganisation.id, 'body')
      .subscribe((listManagedProjects: ProjectSummaryVm[]) => {
        this.listManagedProjects = listManagedProjects;
        if (listManagedProjects?.length === 1) {
          this.selectedProject = listManagedProjects[0];
        }
      });
  }

  changeSelectedOperation(): void {
    this.setInvitation();
  }

  changeSelectedCountry(): void {
    this.selectedOrganisation = undefined;
  }

  changeSelectedOrganisation(organisation: OrganisationVm): void {
    this.selectedOrganisation = organisation;
    this.setInvitation();
  }

  submitInvitation(): void {
    this.isSubmitting = true;

    this.loaderService.open();
    this.projectsService
      .inviteProjectSuppliers(this.selectedProject.id, this.invitation, 'body')
      .pipe(
        finalize(() => {
          this.isSubmitting = false;
          this.loaderService.close();
        })
      )
      .subscribe({
        next: () => {
          this.broadcastService.dispatch(EventsName.SUPPLIER_INVITATION_SENT);
          this.notificationService.notifySuccess();
          this.submitSucceeded.emit();
        },
        error: (error: HttpErrorResponse) => {
          const errorVm: ErrorVm = error.error as ErrorVm;
          this.notificationService.notifyError(errorVm.message);
        },
      });
  }

  cancel(): void {
    this.canceled.emit();
  }

  private setInvitation(): void {
    const invitationMessage = this.translateService.instant(
      'organisation.invite-suppliers.form.message.initial-message',
      {
        supplierName: this.currentUserOrganisation?.name,
        projectName: this.selectedProject?.name,
        projectLocation: this.selectedProject?.location,
      }
    ) as string;

    this.invitation = {
      inviterId: this.currentUserOrganisation?.id.toString(),
      invitedId: this.selectedOrganisation?.id.toString(),
      message: invitationMessage,
      emails: [],
      dateStart: this.selectedProject?.dateStart
        ? new Date(new Date(this.selectedProject.dateStart).setUTCHours(12, 0, 0, 0)).toISOString()
        : undefined,
      dateEnd: this.selectedProject?.dateEnd
        ? new Date(new Date(this.selectedProject.dateEnd).setUTCHours(12, 0, 0, 0)).toISOString()
        : undefined,
      supplierType: SupplierInvitationCreateRequestVm.SupplierTypeEnum.Subcontractor,
    };
    this.invitation.emails = this.invitation.emails.concat(this.selectedOrganisation?.contacts);

    if (this.currentUserOrganisation && this.selectedProject) {
      this.organisationsService
        .getOrganisationInvitations(this.currentUserOrganisation.id, [SupplierInvitationVm.StatusEnum.Accepted], 'body')
        .subscribe((supplierInvitations: SupplierInvitationVm[]) => {
          const invitationProject = supplierInvitations.filter(
            (invitationRecieved) => invitationRecieved.project.id === this.selectedProject.id
          );
          if (invitationProject.length > 0) {
            this.invitation.dateStart = invitationProject[0].dateStart;
            this.invitation.dateEnd = invitationProject[0].dateEnd;
          }
        });
    }
  }
}
